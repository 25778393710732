import React, { useState } from "react"
import Modal from "../layout/modal"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import Subtitle from "../layout/subtitle"
import Button from "../base/buttons/regular"
import { Link } from "gatsby-plugin-intl"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"

const messages = defineMessages({
  introductionPageTitle: "ATOLO Test Rules",
  introductionPageVerticalTitle: "Introduction",
  bodyPartOneA: "This test will evaluate you on several skills: general knowledge of the language, structure and listening.",
  bodyPartOneB:
    "It will adapt its questions as you answer them. Do not worry if some questions seem very difficult: after answering these questions, the test will propose new ones corresponding to your level.",

  introductionPageSubtitle: "Details",
  bodyPartTwoA: "The test has 3 modules. You will need approximately xx minutes to complete it.",
  bodyPartTwoB:
    "Make sure you are isolated (no phone and no mails) and can be focused for that amount of time. You will be able to see your progress thanks to [xxx]. A timer will also be [there] to help you keep track of time. Note that you can leave the test by closing its window and still come back later to finish it. Your progress will be saved.",

  continueButton: "Continue",
})

const Introduction = () => {
  const [navigationVisible, setNavigationVisible] = useState(true)
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")
  const formatMessage = useFormatMessage()

  const currentSize = useWindowSize()

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && navigationVisible) {
    setNavigationVisible(false)
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && !navigationVisible) {
    setNavigationVisible(true)
    setWidthOfModal("w-3/5")
  }

  return (
    <Modal
      title={formatMessage(messages.introductionPageTitle)}
      mdWidth={widthOfModal}
      navigation={true}
      mobile={!navigationVisible}
      lgWidth="w-2/5"
      sideTitle={formatMessage(messages.introductionPageVerticalTitle)}
      next={`/audio${window?.location?.search}`}
    >
      <div className="text-sm mb-2">{formatMessage(messages.bodyPartOneA)}</div>
      <div className="text-sm">{formatMessage(messages.bodyPartOneB)}</div>

      <Subtitle className="text-center mt-5 mb-4">{formatMessage(messages.introductionPageSubtitle)}</Subtitle>
      <div className="text-sm mb-2">{formatMessage(messages.bodyPartTwoA)}</div>
      <div className="text-sm">{formatMessage(messages.bodyPartTwoB)}</div>

      <Link to={`/audio${window?.location?.search}`}>
        <div className="flex justify-center mt-6">
          <Button primary className="w-2/5 text-center text-sm">
            {formatMessage(messages.continueButton)}
          </Button>
        </div>
      </Link>
    </Modal>
  )
}

export default Introduction
