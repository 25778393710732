import React, { useState } from "react"
import Modal from "../layout/modal"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import Subtitle from "../layout/subtitle"
import Button from "../base/buttons/regular"
import { Link } from "gatsby-plugin-intl"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"

const m = defineMessages({
  quickIntroductionPageTitle: "How does the Protectas test work?",
  quickIntroductionPageVerticalBackgroundTitle: "Introduction",
  quickIntroductionPageP1: "There are 5 parts:",
  quickIntroductionPageBullet1: "Contextual recognition",
  quickIntroductionPageBullet2: "Audio comprehension",
  quickIntroductionPageBullet3: "General vocabulary",
  quickIntroductionPageBullet4: "Audio comprehension (conversational)",
  quickIntroductionPageBullet5: "Professional vocabulary",
  quickIntroductionPageBullet5: "Professional vocabulary",
  quickIntroductionPageP2: "Just follow the guidelines.",
  quickIntroductionPageP3:
    "The evaluation will last around 15-20 minutes depending on your level. Make sure you are in a quiet area where you will not be disturbed and prepare sound on your computer or a headset.",
  quickIntroductionPageP4:
    "A progress bar at the top of the screen will inform you of your progress. There is also an automatic countdown for each question. Audio samples can be listened to 3 times.",
  quickIntroductionPageP5: "Good luck and enjoy!",
  quickIntroductionPageSubmitButton: "Good luck and enjoy!",
})

const IntroductionQuick = () => {
  const [navigationVisible, setNavigationVisible] = useState(true)
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")
  const t = useFormatMessage()

  const currentSize = useWindowSize()

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && navigationVisible) {
    setNavigationVisible(false)
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && !navigationVisible) {
    setNavigationVisible(true)
    setWidthOfModal("w-3/5")
  }

  return (
    <Modal
      title={t(m.quickIntroductionPageTitle)}
      mdWidth={widthOfModal}
      navigation={true}
      mobile={!navigationVisible}
      lgWidth="w-2/5"
      sideTitle={t(m.quickIntroductionPageVerticalBackgroundTitle)}
      next={`/audio${window?.location?.search}`}
    >
      <div className="px-8">
        <div className="flex flex-col items-center">
          <div className="text-sm mb-6 font-bold">{t(m.quickIntroductionPageP1)}</div>
          <ul className="text-sm list-disc pl-8">
            <li>{t(m.quickIntroductionPageBullet1)}</li>
            <li>{t(m.quickIntroductionPageBullet2)}</li>
            <li>{t(m.quickIntroductionPageBullet3)}</li>
            <li>{t(m.quickIntroductionPageBullet4)}</li>
            <li>{t(m.quickIntroductionPageBullet5)}</li>
          </ul>
        </div>
        <div className="text-sm mt-6 mb-4 font-bold text-center">{t(m.quickIntroductionPageP2)}</div>
        <div className="text-sm mb-2">{t(m.quickIntroductionPageP3)}</div>
        <div className="text-sm mb-2">{t(m.quickIntroductionPageP4)}</div>
        <div className="text-sm mt-6 mb-4 font-bold text-center">{t(m.quickIntroductionPageP5)}</div>
      </div>
      <Link to={`/audio${window?.location?.search}`}>
        <div className="flex justify-center mt-6">
          <Button primary className="w-2/5 text-center text-sm">
            {t(m.quickIntroductionPageSubmitButton)}
          </Button>
        </div>
      </Link>
    </Modal>
  )
}

export default IntroductionQuick
