import React from "react"
import Layout from "../components/layout"
import Introduction from "../components/test/introduction"
import IntroductionQuick from "../components/test/introduction-quick"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { levelEvaluationTokenSelector } from "src/store/levelEvaluationToken/selectors"
import { levelEvaluationByTokenSelector } from "src/store/levelEvaluationByToken/selectors"
import { EvaluationType } from "src/api/types"
import { useCurrentEvaluation } from "src/utils/hooks/useCurrentEvaluation"

const IntroductionPage = () => {
  const { result: token } = useSelector(levelEvaluationTokenSelector)
  const { result: cachedEvaluation } = useSelector(levelEvaluationByTokenSelector(token))
  const fetchedEvaluation = useCurrentEvaluation()

  const letypecode = cachedEvaluation?.letypecode ?? fetchedEvaluation?.letypecode

  return (
    <Layout type="hr" noHeaderOptions>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Introduction</title>
      </Helmet>
      {letypecode === EvaluationType.EASY ? <IntroductionQuick /> : <Introduction />}
    </Layout>
  )
}

export default IntroductionPage
