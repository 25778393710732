import React from "react"
import { TiArrowBackOutline } from "react-icons/ti"
import { Link } from "gatsby-plugin-intl"
import styled from "styled-components"
import Title from "../layout/title"
import Previous from "../base/buttons/previous"
import Next from "../base/buttons/next"
import GreenLine from "./greenLine"

/* This modal is used in the TestMaker user story. */
const Modal = ({
  title,
  noTitle,
  navigation,
  mobile,
  mdWidth,
  lgWidth,
  next,
  previous,
  children,
  sideTitle,
  className,
  greenLinePercentage = 1,
  greenLineHeight = 1,
}) => {
  return (
    <div className={`w-full bg-red h-full flex flex-1 justify-center items-center ${className}`}>
      <div className="w-32 flex hidden-print ">
        {navigation && !mobile && (
          <Link to={previous} className={`${previous ? "" : "hidden"} mx-10`}>
            <Previous size={36} className="text-gray-400 opacity-50" />
          </Link>
        )}
      </div>

      <div className={`w-full md:${mdWidth} lg:${lgWidth} md:my-2 h-full md:h-auto bg-white sm:rounded px-0`}>
        <GreenLine greenLinePercentage={greenLinePercentage} height={greenLineHeight} />

        {navigation && mobile && (
          <Link to={previous}>
            <TiArrowBackOutline size={32} className={`${previous ? "" : "invisible"}  text-gray-400 opacity-50`} />
          </Link>
        )}
        <div className="flex">
          {sideTitle && (
            <VerticalText className="flex justify-center text-6xl font-bold text-gray-200 opacity-75 -ml-5 -mr-20">{sideTitle}</VerticalText>
          )}
          <div className="py-3 px-4 md:py-8 md:px-10 z-10 w-full">
            {!noTitle && <Title className="text-center">{title} </Title>}
            {children}
          </div>
        </div>
      </div>

      <div className="w-32 flex hidden-print ">
        {navigation && !mobile && (
          <Link to={next} className={`${next ? "" : "hidden"} mx-10`}>
            <Next size={36} className="text-gray-400 opacity-50" />
          </Link>
        )}
      </div>
    </div>
  )
}

export default Modal

const VerticalText = styled.div`
  writing-mode: vertical-rl;
  transform: scale(-1, -1);
  z-index: 1;
`
