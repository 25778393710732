import React from "react"
import { FaChevronCircleRight } from "react-icons/fa"

const Next = ({ onClick, size, className }) => {
  return (
    <FaChevronCircleRight onClick={onClick} size={size} className={className} />
  )
}

export default Next
