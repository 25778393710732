import React from "react"
import { FaChevronCircleLeft } from "react-icons/fa"

const Previous = ({ onClick, size, className }) => {
  return (
    <FaChevronCircleLeft onClick={onClick} size={size} className={className} />
  )
}

export default Previous
