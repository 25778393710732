import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import queryString from "query-string"
import { setLevelEvaluationToken } from "src/store/levelEvaluationToken/actions"
import { setLevelEvaluationByToken } from "src/store/levelEvaluationByToken/actions"
import { getLevelEvaluationByToken } from "src/api"
import { LevelEvaluation } from "src/api/types"

// @ts-ignore
import { navigate } from "gatsby-plugin-intl/link"

export const useCurrentEvaluation = () => {
  const dispatch = useDispatch()

  const [evaluation, setEvaluation] = useState<LevelEvaluation | undefined>()

  const getEvaluation = async () => {
    const search = queryString.parse(window.location.search)

    if (!search?.levelevaluation) {
      window.location.href = "https://www.atolo.eu/"
      return
    }

    const levelEvaluationToken = `${search.levelevaluation}`
    dispatch(setLevelEvaluationToken(levelEvaluationToken))

    try {
      const result = await getLevelEvaluationByToken(levelEvaluationToken)
      dispatch(setLevelEvaluationByToken(levelEvaluationToken, result))

      // If levelEvaluation is running, go straight to it instead of showing landing page
      if (result && !result?.finallevel && result.currentstep !== 0) {
        navigate(`/level-evaluation?token=${result?.token}`)
      } else if (result && result.finallevel) {
        navigate(`/level-evaluation?token=${result?.token}`)
      } else {
        setEvaluation(result)
      }
    } catch (error) {
      if ((error as Error)?.message === "LevelEvaluation Not Found") {
        window.location.href = "https://www.atolo.eu/"
        return
      }
    }
  }

  useEffect(() => {
    getEvaluation()
  }, [window?.location?.href])

  return evaluation
}
