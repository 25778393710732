import { useState, useEffect } from "react"

export function useWindowSize() {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }) // Empty array ensures that effect is only run on mount and unmount

  if (windowSize.width <= 640) {
    return "sm"
  }
  if (windowSize.width <= 768 && windowSize.width > 640) {
    return "md"
  }
  if (windowSize.width <= 1024 && windowSize.width > 768) {
    return "lg"
  }
  if (windowSize.width >= 1024) {
    return "lx"
  }
}
